html
  line-height: 1.5
  font-weight: 300
@media screen and (max-width: 600px)
  html
    font-weight: 400

body
  margin: 0
  padding: 0
  background-color: #fff
  color: #333
  font-size: 16px
  font-family: "Noto Sans JP",sans-serif
  font-feature-settings: "palt"
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

@media screen and (max-width: 1080px)
  body.menu-opened
    overflow: hidden

h1
  font-size: 28px
  color: #8cf
h2
  font-size: 24px
h3
  font-size: 20px
h1, h2, h3
  font-weight: 300

@media screen and (max-width: 1280px)
  h1
    font-size: 24px

@media screen and (max-width: 1080px)
  h1, h2, h3
    margin: 0

header
  position: fixed
  top: 0
  left: 0
  right: 0
  background-color: rgba(0,0,0,.7)
  z-index: 10
  display: flex
  justify-content: space-between
  h1
    text-align: left
    margin: 16px 0 16px 20px

@media screen and (max-width: 1080px)
  header h1
    font-size: 24px
    margin: 17px 10px
    width: 8.5em
    text-align: center
    
@media screen and (max-width: 600px)
  header h1
    font-size: 18px
    margin: 24px 0
    
header
  nav.main-nav
    ul
      margin-right: 10px
      padding: 0
      li
        display: inline-block
        padding: .25em
        a
          color: #fff
          text-decoration: none
          &:hover
            color: #cff
          &.logo
            font-size: 18px
          &.on
            color: #8cf
            text-decoration: underline
            cursor: default

@media screen and (max-width: 1080px)
  header nav.main-nav
    height: 0
    transition: height .3s
    ul
      margin-top: -436px
      transition: margin-top .3s
      li
        display: block
    &.show
      height: 386px
      ul
        margin-top: 50px
    
header nav.sp-nav
  display: none
  position: fixed
  top: 0
  right: 0
  width: 120px

@media screen and (max-width: 1080px)
  header nav.sp-nav
    display: block
    
header nav.sp-nav
  .menu-button
    position: absolute
    top: 25px
    right: 20px
    cursor: pointer
    width: 25px
    height: 26px
    padding: 0
    z-index: 3
    background: 0 0
    border: none
    &:focus
      outline: 0
    &:focus-visible
      outline: solid 1px #8cf
    .bar
      display: block
      position: relative
      width: 25px
      height: 1px
      border-bottom: solid 3px #fff
      transition: all .3s
      &.bar01
        top: -7px
        &.clicked
          transform: rotate(45deg)
          top: 5px
      &.bar02
        top: 0
        &.clicked
          opacity: 0
      &.bar03
        top: 7px
        &.clicked
          transform: rotate(-45deg)
          top: -3px
  .menu-guide
    position: absolute
    top: 27px
    margin: 0
    z-index: 1
    color: #fff
    &.hidden
      display: none

.first-view
  height: 85vh
  position: relative
  background-size: cover

@media screen and (max-width: 600px)
  .first-view
    height: 50vh
    
section
  width: 80%
  margin: 2em auto

.line_through
  text-decoration: line-through

.text-red
  color: #ff5252

main a
  color: #339
  &:hover
    color: #999

footer
  width: 100%
  height: 32px
  background-color: #666
  p
    line-height: 2
    color: #fff
    text-align: right
    margin-top: 0
    margin-right: 20px
    a
      color: #8cf
      &:hover
        color: #cff

@media screen and (max-width: 600px)
  footer p
    font-size: 10px
    line-height: 3
